import React from 'react'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import { makeStyles } from '@material-ui/core'
import { SanityFigure } from '../../../@types/graphql-types'

const useStyles = makeStyles(() => ({
  img: {
    width: `100%`,
  },
  figure: {
    margin: 0,
  },
}))
const Figure: React.FC<SanityFigure> = (props) => {
  const classes = useStyles(undefined)
  return (
    <figure className={classes.figure}>
      {props.asset && (
        <img
          src={imageUrlFor(buildImageObj(props)).width(1200).url()}
          alt={props.alt}
          className={classes.img}
        />
      )}
      <figcaption>{props.caption}</figcaption>
    </figure>
  )
}

export default Figure
